import { lazy } from 'react'

import { ProgressHeightLookup, ProgressLayoutLookup } from './types'

const ProgressDefault = lazy(() => import('./progress-default'))
const ProgressWithPercents = lazy(() => import('./progress-with-percents'))
const ProgressWithPercentsV2 = lazy(() => import('./progress-with-percents-v2'))
const ProgressColoredCheckpoints = lazy(() => import('./progress-colored-checkpoints'))
const ProgressSeparatedPoints = lazy(() => import('./progress-separated-points'))

export enum ProgressModeTypes {
   COLORED_CHECKPOINTS = 'colored-checkpoints',
   DEFAULT = 'default',
   SEPARATED_POINTS = 'separated-points',
   WITH_PERCENTS = 'with-percents',
   WITH_PERCENTS_V2 = 'with-percents-v2',
}

export const PROGRESS_LAYOUT_LOOKUP: ProgressLayoutLookup = {
  [ProgressModeTypes.DEFAULT]: ProgressDefault,
  [ProgressModeTypes.WITH_PERCENTS]: ProgressWithPercents,
  [ProgressModeTypes.WITH_PERCENTS_V2]: ProgressWithPercentsV2,
  [ProgressModeTypes.COLORED_CHECKPOINTS]: ProgressColoredCheckpoints,
  [ProgressModeTypes.SEPARATED_POINTS]: ProgressSeparatedPoints,
}

export const PROGRESS_HEIGHT_LOOKUP: ProgressHeightLookup = {
   [ProgressModeTypes.DEFAULT]: 60,
   [ProgressModeTypes.WITH_PERCENTS]: 80,
   [ProgressModeTypes.WITH_PERCENTS_V2]: 90,
   [ProgressModeTypes.COLORED_CHECKPOINTS]: 94,
   [ProgressModeTypes.SEPARATED_POINTS]: 94,
 }
