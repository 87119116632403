import { createAsyncThunk } from '@reduxjs/toolkit';
import _pick from 'lodash/pick';
import _random from 'lodash/random';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import { ThunkAction } from 'redux-thunk';
import i18next from 'i18next';
import dayjs from 'dayjs';

import Billing from '@web-solutions/module-billing';
import Analytics from '@web-solutions/module-analytics';
import Attribution from '@web-solutions/module-attribution';

import { setApiAuth } from 'core/utils/network';
import { stringifyUrlParams } from 'core/utils/url-sync';
import { getApproximateLocation } from 'core/api/nominatim';

import { updateUserEmail } from 'src/api/auth';
import { auth as authRequest } from 'src/api/auth';
import { update as updateProfile, G_IDS, R_IDS, getPalmReport, savePalm, saveFutureGoals, updateProfileParams } from 'src/api/profile';
import { RELATIONSHIP } from 'src/constants/profile';
import { maxMatchingPercent, minMatchingPercent } from 'src/screens/quiz/advisors-loader/constants';

import { bestAdvisorsData } from 'src/components/advisor-cards-swiper/constants';
import { AdvisorCardData } from 'src/components/advisor-cards-swiper/types';

import { isAsb } from '../app/utils';
import { setPending } from '../app/actions';
import type { AppThunk, RootState } from '../types'

import { birthDate2Age, profileParamsMapper } from './utils';

import { profileSlice } from '.';


export const {
  setAge,
  setApproximateLocation,
  setAstologerTimeBalance,
  setAstrologerTimeBalance,
  setAuthorized,
  setBirthDate,
  setBirthPlace,
  setBirthTime,
  setColor,
  setCriticalDirection,
  setDesirableGoals,
  setElement,
  setEmail,
  setFutureGoals,
  setGender,
  setGoals,
  setHandImg,
  setHandResults,
  setIsUnsubscriber,
  setLang,
  setLinkAuthResult,
  setName,
  setPalmReport,
  setRelStatus,
  setRelyOn,
  setPersonalInsight,
  setShippingAddress,
  setShippingMethod,
  generateSoulmateInfo,
  setAdvisorsData,
  setSelectedAdvisorId,
  setChatUnlimited,
  setTopics,
  setTypesOfReading
} = profileSlice.actions;

export const processEmail = (email: string, { skipFirebase }: { skipFirebase?: boolean } = {}): ThunkAction<void, any, unknown, any> =>
  (dispatch, getState) => {
    if (email !== getState().profile.email) {
      Analytics.setUserProperty('email', email);
      Analytics.trackEvent('user', 'info', { email, });
      dispatch(setEmail(email));
      stringifyUrlParams({ email })

      if (!skipFirebase) {
        Analytics.trackEvent('firebase_auth', 'update_email_submit', { email });
        updateUserEmail(email)
          .then(() => {
            Analytics.trackEvent('firebase_auth', 'update_email_success', { email });
          })
          .catch((error) => {
            Analytics.trackEvent('firebase_auth', 'update_email_error', { error: error?.message, code: error?.code, email });
          });
      }
    }
  }


export const saveFutureGoalsData = createAsyncThunk(
  `${profileSlice.name}/saveFutureGoalsData`,
  async (goals: Record<string, any>[], { dispatch }) => {
    dispatch(setFutureGoals(goals));
    const data = {
      goals: goals.map((goal) => goal.id)
    }
    saveFutureGoals(data)
      .catch((e) => {
        console.log('[SAVE GOALS ERROR]: ', e)
      })
  })

export const auth = createAsyncThunk(
  `${profileSlice.name}/auth`,
  async (_, { dispatch }) => {
    return authRequest()
      .then((res: any) => {
        const h = `Bearer ${res?.profile?.access_token}`;
        setApiAuth(h);
        Billing.setApiAuth(h);

        if (sessionStorage.getItem('completed') === 'true') {
          if (res?.profile?.name) {
            dispatch(setName(res?.profile?.name));
          }

          if (res?.profile?.birthdate && res?.profile?.birthdate !== '2000-01-01') {
            dispatch(setBirthDate(res?.profile?.birthdate));
          }
          //TODO: check data from backend
          dispatch(setGender(G_IDS[res?.profile?.gender] || null));
          dispatch(setRelStatus(R_IDS[res?.profile?.marital_status] || null));
          dispatch(setChatUnlimited(res?.profile?.data?.is_chat_unlimited));
          dispatch(setLang(i18next.language));
        }

        dispatch(setEmail(res?.profile?.email));
        dispatch(setAuthorized());

        if (!isAsb()) {
          getApproximateLocation()
            .then(r => {
              dispatch(setApproximateLocation(r));
            })
            .catch((err) => {
              console.warn(err);
            })
        }
      });
  }
)

export const authAndSetUserData = createAsyncThunk(
  `${profileSlice.name}/authAndSetUserData`,
  async (isSetPending: boolean | undefined, { dispatch, getState }) => {
    const { profile: p, remoteConfig } = getState() as RootState;

    if (isSetPending) {
      dispatch(setPending(true));
    }

    const { isEmpty, params: profileParams } = profileParamsMapper({ is_welcome_numerology_mails_modified: remoteConfig.isWelcomeNumerologyMailsModified, traffic_source: remoteConfig.trafficSource })

    if (!isEmpty) {
      try {
        await updateProfileParams(profileParams)
      } catch (error) {
        console.log('[ERROR UPDATE PROFILE PARAMS]', error);
      }
    }

    if (p?.birthDate && p?.birthPlace?.lon && p?.birthPlace?.lat) {
      Analytics.setUserProperty('birhchart_available', true);
    } else {
      Analytics.setUserProperty('birhchart_available', false);
    }

    try {
      await updateProfile(p);
      Analytics.trackEvent('profile', 'saved', { ..._pick(p, ['email', 'name', 'relStatus', 'birthDate', 'birthTime', 'birthPlace']) });
    }
    catch (error: any) {
      if (error?.status !== 422) {
        throw error;
      }
      console.log('[ERROR AUTH]', error);
    }
    finally {
      if (isSetPending) {
        dispatch(setPending(false));
      }
    }
  }
)

export const getPalmReportData = createAsyncThunk(
  `${profileSlice.name}/getPalmReportData`,
  async (_, { dispatch }) => {
    getPalmReport()
      .then((r: any) => {
        if (r.palm_reading) {
          try {
            const report = {
              ...r.palm_reading,
              left_hand_dots: r.palm_reading.left_hand_dots ? JSON.parse(r.palm_reading.left_hand_dots) : null,
              right_hand_dots: r.palm_reading.right_hand_dots ? JSON.parse(r.palm_reading.right_hand_dots) : null
            }
            dispatch(setPalmReport(report));
          } catch (err) {
            console.log('[PARSING ERROR]:', err)
          }
        }
      });
  }
)

export const processGender = createAsyncThunk(
  `${profileSlice.name}/processGender`,
  async (gender: string, { dispatch }) => {
    Analytics.setUserProperty('gender', gender);
    Analytics.trackEvent('user', 'info', { gender });
    dispatch(setGender(gender));
  }
)

export const processBirthDate = createAsyncThunk(
  `${profileSlice.name}/processBirthDate`,
  async (date: string, { dispatch }) => {
    const age = birthDate2Age(date);

    Analytics.setUserProperty('age', age);
    Analytics.trackEvent('user', 'info', { birth_date: dayjs(date).format('YYYYMMDD'), age });

    dispatch(setBirthDate(date));
    dispatch(setAge(age));
  }
)

export const processRelStatus = createAsyncThunk(
  `${profileSlice.name}/processRelStatus`,
  async (status: string, { dispatch }) => {
    Analytics.setUserProperty('relStatus', status === 'broke_up' ? RELATIONSHIP.SINGLE : status);
    dispatch(setRelStatus(status));
  }
)

export const savePalmData = (url: string, data: any): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch) => {
  const formData = new FormData();
  formData.append('image_file', url.replace('data:image/jpeg;base64,', ''));
  if (data) {
    formData.append('dots', JSON.stringify(data));
  }

  return savePalm(formData)
    .then((r: any) => {
      const palmReading = r.palm_reading
      try {
        const report = {
          ...palmReading,
          left_hand_dots: palmReading.left_hand_dots ? JSON.parse(palmReading.left_hand_dots) : null,
          right_hand_dots: palmReading.right_hand_dots ? JSON.parse(palmReading.right_hand_dots) : null
        }
        dispatch(setPalmReport(report));
      } catch (err) {
        console.log('[PARSING ERROR]:', err)
      }
    });
}

export const generateBestAdvisorsData = (): AppThunk => (dispatch, getState) => {
  const {
    profile: { advisorsData },
  } = getState();

  if (!advisorsData.length) {
    const data: AdvisorCardData[] = bestAdvisorsData.map(
      (advisor) => {
        return {
          ...advisor,
          matching: _random(minMatchingPercent, maxMatchingPercent),
        };
      }
    );
    dispatch(setAdvisorsData(_reverse(_sortBy(data, ['matching']))));
  }
};

export const processAdvisorSelection = (id: number): AppThunk => (dispatch, getState) => {
  const {
    profile: { selectedAdvisorId },
  } = getState();

  if (selectedAdvisorId !== id) {
    dispatch(setSelectedAdvisorId(id));
    Attribution.setURLBaseParams({ urlExtraPath: 'chat', query: { advisor_id: `${id}` } });
  }
};